import { fromJS, Map } from 'immutable';

import {
  RESET_ACTIVITIES,
  SET_ACTIVITIES,
  SET_LOADING_ACTIVITIES_FLAG,
  SET_LOADING_FLAG,
  SET_MODULE_SUGGESTIONS,
  SET_PROJECT,
} from './constants';

export const initialState = fromJS({
  loading: false,
  areActivitiesLoading: true,
  project: {},
  activities: new Map(),
  module_suggestions: [],
});

function homePageReducer(state = initialState, action) {
  switch (action.type) {
    case SET_LOADING_FLAG:
      return state.set('loading', action.loading);
    case SET_LOADING_ACTIVITIES_FLAG:
      return state.set('areActivitiesLoading', action.areActivitiesLoading);
    case SET_PROJECT:
      return state.set('project', fromJS(action.project));
    case SET_MODULE_SUGGESTIONS:
      return state.set('module_suggestions', fromJS(action.modules));
    case SET_ACTIVITIES: {
      const activitiesState = state.get('activities').get(action.projectId) || [];
      return state.set(
        'activities',
        state.get('activities').set(action.projectId, activitiesState.concat(action.activities)),
      );
    }
    case RESET_ACTIVITIES:
      return state.set('activities', state.get('activities').clear());
    default:
      return state;
  }
}
export default homePageReducer;
