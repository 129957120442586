import { createSelector } from 'reselect';

import { initialState } from './reducer';

const selectFormsPage = state => state.formsPage || initialState;

const selectLoadingFlag = () =>
  createSelector(selectFormsPage, priorityDataState => priorityDataState.get('loading'));

const selectPriorities = () =>
  createSelector(selectFormsPage, dataState => dataState.get('priorities').toJS());

const selectTemplates = () =>
  createSelector(selectFormsPage, dataState => dataState.get('templates').toJS());

const selectPhases = () =>
  createSelector(selectFormsPage, dataState => dataState.get('phases').toJS());

const selectLastConfig = () =>
  createSelector(selectFormsPage, dataState => dataState.get('lastConfigs').toJS());

const selectStatusList = () =>
  createSelector(selectFormsPage, dataState => dataState.get('statusList').toJS());

export {
  selectPriorities,
  selectTemplates,
  selectLoadingFlag,
  selectPhases,
  selectLastConfig,
  selectStatusList,
};
