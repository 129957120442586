import React, { useState } from 'react';

import { InputAdornment, ListItem, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';

import RemoveScrollbar from 'components/RemoveScrollbar';
import SearchTextField from 'components/SearchTextFieldV3';
import SearchIcon from 'components/SvgComponents/icons/Search';
import Settings from 'components/SvgComponents/icons/Settings';
import SectionTree from 'components/Tree/SectionTree';
import SectionTreeWrap from 'components/Tree/SectionTreeWrap';
import combineStyles from 'theme/combineStyles';
import { styles as navLinkStyle } from 'theme/nav-link-theme';
import globalMessages from 'translations/messages/global-messages';
import { versionsState } from 'utils/constants';

import { setSelectedTreeNode, setFormLanguage, saveTreeOrder } from '../actions';
import {
  selectFormData,
  selectFormTree,
  selectSelectedTreeNode,
  selectFormLanguage,
  selectLoadingFlag,
} from '../selectors';
import ChangeLanguage from './ChangeLanguage';

export const styles = theme => ({
  formSettings: {
    marginTop: 40,
  },
  search: {
    width: '100%',
    borderRadius: 12,
    marginTop: theme.spacing(4),
    backgroundColor: theme.palette.background.primary,
    '& input': {
      color: theme.palette.text.primary,
    },
  },
  searchIcon: {
    color: theme.palette.text.primary,
  },
});

function FormTree(props) {
  const {
    classes,
    formTree,
    intl,
    selectedTreeNode,
    formData,
    loading,
    selectedFormLanguage,
  } = props;

  const readOnly = formData?.state !== versionsState.draft;
  const [searchText, setSearchText] = useState('');

  const getDataForTree = () => formTree.form_template_parts || [];

  const handleClearSelect = () => props.dispatchSetSelectedTreeNode({});

  const handleItemSelect = (idItem, data) => {
    if (data.selected) {
      const itemClicked = data.node.props.item;
      props.dispatchSetSelectedTreeNode(itemClicked);
    }
  };

  const handlesNewTreeData = data => props.dispatchSaveTreeOrder(data);

  return (
    <>
      <ListItem
        button
        className={classes.formSettings}
        classes={{
          root: classNames({
            [classes.listItem]: true,
            [classes.active]: !selectedTreeNode.id,
          }),
        }}
        onClick={handleClearSelect}
      >
        <Settings width={16} height={16} />
        <Typography color="inherit" className={classNames(classes.label, classes.labelMultiline)}>
          {intl.formatMessage(globalMessages.form_settings)}
        </Typography>
      </ListItem>
      <ChangeLanguage
        formLanguages={(formData?.form_languages || []).map(item => item.language)}
        onChange={props.dispatchSetFromLanguage}
        language={selectedFormLanguage}
      />
      <SearchTextField
        variant="outlined"
        className={classes.search}
        searchText={searchText}
        onChange={setSearchText}
        placeholder={intl.formatMessage(globalMessages.search_dot)}
        searchInputProps={{
          startAdornment: (
            <InputAdornment position="start" style={{ pointerEvents: 'none' }}>
              <SearchIcon className={classes.searchIcon} />
            </InputAdornment>
          ),
          endAdornment: null,
        }}
      />
      <RemoveScrollbar>
        <SectionTreeWrap>
          <SectionTree
            disabled={loading}
            searchTreeText={searchText}
            selectedTreeNode={selectedTreeNode}
            isDraggable={!readOnly}
            data={getDataForTree()}
            selectedKeys={selectedTreeNode ? [selectedTreeNode.id] : []}
            setNewTreeData={handlesNewTreeData}
            title="Form manager"
            onSelectItem={handleItemSelect}
          />
        </SectionTreeWrap>
      </RemoveScrollbar>
    </>
  );
}

FormTree.propTypes = {
  classes: PropTypes.object,
  formTree: PropTypes.object,
  formData: PropTypes.object,
  loading: PropTypes.bool,
  selectedFormLanguage: PropTypes.string,
  selectedTreeNode: PropTypes.object,
  intl: intlShape.isRequired,
  dispatchSetSelectedTreeNode: PropTypes.func,
  dispatchSetFromLanguage: PropTypes.func,
  dispatchSaveTreeOrder: PropTypes.func,
};

FormTree.defaultProps = {
  classes: {},
};

const mapStateToProps = createStructuredSelector({
  formTree: selectFormTree(),
  formData: selectFormData(),
  loading: selectLoadingFlag(),
  selectedTreeNode: selectSelectedTreeNode(),
  selectedFormLanguage: selectFormLanguage(),
});

const mapDispatchToProps = {
  dispatchSetSelectedTreeNode: setSelectedTreeNode,
  dispatchSetFromLanguage: setFormLanguage,
  dispatchSaveTreeOrder: saveTreeOrder,
};

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  injectIntl,
  withConnect,
  withStyles(combineStyles(styles, navLinkStyle)),
)(FormTree);
