import { configureStore, isImmutableDefault } from '@reduxjs/toolkit';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { isImmutable } from 'immutable';
import createSagaMiddleware from 'redux-saga';

import { formsTemplatesApi } from 'containers/FormsTemplatesPage/formsTemplatesApi';
import { meetingsApi } from 'containers/OneProjectPage/MeetingsPage/meetingsApi';
import history from 'utils/history';

import { apiKeyGeneratorApi } from './components/ApiKeyGenerator/apiKeyGeneratorApi';
import { companiesApi } from './containers/OneProjectPage/ProjectCompaniesPage/companiesApi';
import createReducer from './reducers';

export default function configureStoreFC() {
  const reduxSagaMonitorOptions = {};

  const sagaMiddleware = createSagaMiddleware(reduxSagaMonitorOptions);

  // Create the store with three middlewares
  // 1. sagaMiddleware: Makes redux-sagas work
  // 2. routerMiddleware: Syncs the location/URL path to the state
  // 3. companiesApi.middleware: Handles the API calls for companies

  const store = configureStore({
    reducer: createReducer({
      router: connectRouter(history),
    }),
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        serializableCheck: false,
        immutableCheck: {
          isImmutable: value => isImmutable(value) || isImmutableDefault(value),
        },
      })
        .concat(sagaMiddleware)
        .concat(routerMiddleware(history))
        .concat(companiesApi.middleware)
        .concat(apiKeyGeneratorApi.middleware)
        .concat(meetingsApi.middleware)
        .concat(formsTemplatesApi.middleware),
  });
  // Extensions
  store.runSaga = sagaMiddleware.run;
  store.injectedReducers = {}; // Reducer registry
  store.injectedSagas = {}; // Saga registry

  store.injectedReducers.meetingsApi = meetingsApi.reducer;
  store.injectedReducers.formsTemplatesApi = formsTemplatesApi.reducer;

  // Make reducers hot reloadable, see http://mxs.is/googmo
  if (import.meta.webpackHot) {
    import.meta.webpackHot.accept('./reducers', () => {
      store.replaceReducer(createReducer(store.injectedReducers));
    });
  }

  return store;
}
