import React from 'react';

import PropTypes from 'prop-types';

import InlineDropdownButton from 'components/Dropdown/InlineDropdownButton';
import StatusCellSkeleton from 'components/SkeletonScreen/components/StatusCellSkeleton';

export const StatusCell = ({ listsRequestOption, selected, itemId, loading, disabled }) => {
  if (!itemId) return loading ? <StatusCellSkeleton /> : <div>-</div>;
  return (
    <InlineDropdownButton
      disabled={disabled}
      listsRequestOption={listsRequestOption}
      selected={selected}
      itemId={itemId}
    />
  );
};

StatusCell.propTypes = {
  listsRequestOption: PropTypes.object,
  selected: PropTypes.object,
  disabled: PropTypes.bool,
  itemId: PropTypes.string,
  loading: PropTypes.bool,
};

export default StatusCell;
