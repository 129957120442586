import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import config from 'config/config';
import checkOrSetSlash from 'utils/checkOrSetSlash';
import { prepareFCHeader } from 'utils/request';

const rootProjectPath = `${checkOrSetSlash(config.apiHostGateway, 'apiHostGateway')}api/`;

export const meetingsApi = createApi({
  reducerPath: 'meetingsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: rootProjectPath,
    prepareHeaders: prepareFCHeader(),
  }),
  tagTypes: ['Meeting'],
  endpoints: builder => ({
    getMeetings: builder.query({
      query: ({ projectId, search_term, pagination }) => ({
        url: `projects/${projectId}/meetings/filter`,
        method: 'POST',
        body: {
          pagination,
          search_term,
        },
      }),
      providesTags: ['Meeting'],
    }),
  }),
});

export const { useGetMeetingsQuery } = meetingsApi;
