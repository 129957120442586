import React, { useCallback, useEffect, useState } from 'react';

import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';

import Dialog from 'components/dialogs/Dialog';
import { setGlobalInfo } from 'containers/ErrorHandler/actions';
import { selectLimitsOfferForms } from 'containers/LimitationMessageOffer/selectors';
import { useFeatureFlags } from 'contexts/FeatureFlagsProvider';
import { Routing } from 'routing/routing';
import globalMessages from 'translations/messages/global-messages';
import Analytics from 'utils/Analytics';
import injectSaga from 'utils/injectSaga';
import { validateEmail } from 'utils/validators';

import {
  addFormInstance,
  clearTemplates,
  getAvailableForms,
  getInformationsAboutAvailableForms,
} from '../actions';
import saga from '../saga';
import { selectLoadingFlag } from '../selectors';
import FormAddList from './FormAddList';
import ListTemplateAdd from './ListTemplateAdd';

/**
 * AddForm
 */
function AddForm(props) {
  const {
    activeDialog,
    onCloseDialog,
    intl,
    limitsForms,
    loading,
    match,
    history,
    dispatchGetAvailableForms,
    dispatchGetInformationsAboutAvailableForms,
    moduleId,
    position,
    disableRedirect,
    onFormCreated,
    source,
  } = props;
  const { organizationId, idData, itemId } = match.params;
  const [searchText, setSearchText] = useState('');
  const [submit, setSubmit] = useState(false);
  const [availableForms, setAvailableForms] = useState([]);
  const [formsIdsForModuleSelected, setFormsIdsForModuleSelected] = useState([]);
  const featureFlag = useFeatureFlags();

  const getInformationsAboutAvailableFormsCallback = data => {
    setAvailableForms(data.forms);
  };

  const getAvailableFormsCallback = data => {
    const formsIds = data.forms.map(form => form.form_id);
    setFormsIdsForModuleSelected(formsIds);
    dispatchGetInformationsAboutAvailableForms({
      params: match.params,
      formsIds,
      callback: getInformationsAboutAvailableFormsCallback,
    });
  };

  useEffect(
    () => {
      if (activeDialog) {
        dispatchGetAvailableForms({
          moduleId: moduleId || itemId,
          callback: getAvailableFormsCallback,
          hierarchyActivated: featureFlag?.module_forms_hierarchy,
        });
      }
    },
    [activeDialog, match.params, dispatchGetAvailableForms],
  );

  const limitReached = limitsForms?.percentage >= 100;

  const debounceFetchData = useCallback(
    debounce((value, formsIds) => {
      dispatchGetInformationsAboutAvailableForms({
        params: match.params,
        formsIds,
        callback: getInformationsAboutAvailableFormsCallback,
        searchTerm: value,
      });
    }, 1000),
    [],
  );
  if (limitReached) {
    return null;
  }

  const handleCloseDialog = () => {
    onCloseDialog();
    setSearchText('');
    setSubmit(false);
    Analytics.track('forms_creation_cancelled');
  };

  const closeDialog = () => {
    onCloseDialog();
    setSearchText('');
    setSubmit(false);
  };

  const onSubmit = data => {
    const { categoryId } = data;
    setSubmit(true);
    props.dispatchAddFormInstance(
      { form: data, position, moduleId, source },
      moduleId || itemId,
      featureFlag,
      categoryId,
      newFormData => {
        if (onFormCreated) {
          onFormCreated();
        }

        closeDialog();

        if (!disableRedirect) {
          history.push(Routing.forms.detail(organizationId, idData, itemId, newFormData.id));
        }
      },
    );
  };
  const displayOtherOptionOnNoResult = ({ itemsToAdd }) =>
    validateEmail(searchText) && !itemsToAdd.find(template => template.name === searchText);

  const handleSearchTextChange = value => {
    setSearchText(value);
    debounceFetchData(value, formsIdsForModuleSelected);
  };

  return (
    <Dialog
      id="dialog-settings"
      open={activeDialog}
      fullWidth
      maxWidth="md"
      onCloseDialog={handleCloseDialog}
      dialogTitleNode={intl.formatMessage(globalMessages.add_form)}
    >
      <FormAddList
        items={availableForms}
        itemsRendered={<ListTemplateAdd />}
        itemComparison={(a, b) => a.name === b.name}
        displayOtherOptionOnNoResult={displayOtherOptionOnNoResult}
        searchText={searchText}
        onClearTemplates={props.dispatchClearTemplates}
        searchPlaceHolder={intl.formatMessage(globalMessages.search_dot)}
        onSearchTextChange={handleSearchTextChange}
        noResultsFoundText={intl.formatMessage(globalMessages.try_different_search_terms)}
        submitting={submit}
        onSubmit={onSubmit}
        onClose={handleCloseDialog}
        loading={loading}
        moduleId={moduleId}
      />
    </Dialog>
  );
}

AddForm.propTypes = {
  intl: intlShape.isRequired,
  activeDialog: PropTypes.bool,
  dispatchGetAvailableForms: PropTypes.func,
  dispatchAddFormInstance: PropTypes.func,
  dispatchClearTemplates: PropTypes.func,
  onCloseDialog: PropTypes.func,
  loading: PropTypes.bool,
  limitsForms: PropTypes.object,
  match: PropTypes.object,
  history: PropTypes.object,
  onFormCreated: PropTypes.func,
  moduleId: PropTypes.string,
  position: PropTypes.number,
  disableRedirect: PropTypes.bool,
  source: PropTypes.string,
  dispatchGetInformationsAboutAvailableForms: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
  loading: selectLoadingFlag(),
  limitsForms: selectLimitsOfferForms(),
});

const mapDispatchToProps = {
  dispatchGetAvailableForms: getAvailableForms,
  dispatchAddFormInstance: addFormInstance,
  dispatchClearTemplates: clearTemplates,
  dispatchSetGlobalInfo: setGlobalInfo,
  dispatchGetInformationsAboutAvailableForms: getInformationsAboutAvailableForms,
};

const withSaga = injectSaga({ key: 'formsPage', saga });

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  injectIntl,
  withRouter,
  withConnect,
  withSaga,
)(AddForm);
