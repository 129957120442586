import React, { useRef } from 'react';

import { Button as MuiButton, styled, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { selectVisibility } from 'components/Dropdown/selectors';
import ChevronDrown from 'components/SvgComponents/icons/ChevronDown';
import globalMessages from 'translations/messages/global-messages';
import { isDeleted } from 'utils/delete-utils';
import { getTranslation } from 'utils/localization';

import { setDropdownData } from './actions';

// #region [styled]
const MuiButton_Custom = styled(MuiButton)(({ theme, state }) => ({
  height: '22px',
  minWidth: 'fit-content',
  maxWidth: 'fit-content',
  fontFamily: 'Effra Medium, Roboto',
  background: state ? `${state.color}1A` : theme.palette.background.primary,
  borderRadius: theme.spacing(),
  textTransform: 'capitalize',
  padding: '0.125rem 0.5rem',
}));

const Typography_Custom = styled(Typography)(({ state }) => ({
  color: state?.color,
}));

const ChevronDrown_Custom = styled(ChevronDrown)(() => ({
  marginLeft: '0.375rem',
}));
// #endregion

export const InlineDropdownButton = ({ intl, itemId, selected, disabled }) => {
  // #region [redux]
  const dispatch = useDispatch();
  const visibility = useSelector(selectVisibility());
  // #endregion

  // #region [refs]
  const ref = useRef();
  // #endregion

  // #region [methods]
  const handleBtnClick = evt => {
    evt.stopPropagation();
    dispatch(
      setDropdownData({
        visibility: !visibility,
        reference: ref,
        id: !visibility ? itemId : null,
        selected: !visibility ? selected?.id : null,
      }),
    );
  };
  // #endregion

  // #region [render]
  if (!selected) return <div>-</div>;
  return (
    <MuiButton_Custom
      ref={ref}
      size="small"
      disabled={disabled}
      state={selected}
      onClick={handleBtnClick}
    >
      <Typography_Custom
        noWrap
        state={selected}
        variant="subtitle"
        title={getTranslation(selected.names?.translations, selected.name)}
      >
        {getTranslation(selected.names?.translations, selected.name)}
      </Typography_Custom>
      {isDeleted(selected) && (
        <Typography noWrap component="p" variant="bodyLight">
          {intl.formatMessage(globalMessages.deleted)}
        </Typography>
      )}
      {!disabled && <ChevronDrown_Custom width={12} height={10} color={selected.color} />}
    </MuiButton_Custom>
  );
  // #endregion
};

InlineDropdownButton.propTypes = {
  intl: intlShape.isRequired,
  itemId: PropTypes.string.isRequired,
  selected: PropTypes.object,
  disabled: PropTypes.bool,
};

export default injectIntl(InlineDropdownButton);
