import React from 'react';

import { styled } from '@mui/material';
import PropTypes from 'prop-types';

// #region [styled]
const Div_TextSkeleton = styled('div')(({ theme }) => ({
  height: '10px',
  width: '100%',
  background: theme.palette.background.primary,
  borderRadius: '5px',
  animation: `colorChange ease-in-out 4s infinite`,
  '@keyframes colorChange': {
    '25%': {
      background: theme.palette.background.primary,
    },
    '75%': {
      background: theme.palette.background.secondary,
    },
    '100%': {
      background: theme.palette.background.primary,
    },
  },
}));
// #endregion

const TextSkeleton = ({ className }) => (
  // #region [render]
  <Div_TextSkeleton className={className} />
  // #endregion
);

TextSkeleton.propTypes = {
  className: PropTypes.string,
};

export default TextSkeleton;
