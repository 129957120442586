import { fromJS } from 'immutable';
import { SET_DROPDOWN_DATA } from './constants';

export const initialState = fromJS({
  id: null,
  visibility: false,
  reference: {},
  selected: null,
});

function tableDropdown(state = initialState, action) {
  switch (action.type) {
    case SET_DROPDOWN_DATA:
      return state
        .set('id', fromJS(action.id))
        .set('visibility', fromJS(action.visibility))
        .set('selected', fromJS(action.selected))
        .set('reference', fromJS(action.reference));
    default:
      return state;
  }
}
export default tableDropdown;
