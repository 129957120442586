import {
  ATTACH_PROJECT_MEDIA,
  DETACH_PROJECT_MEDIA,
  FETCH_PROJECT,
  GET_ACTIVITIES,
  GET_FORM,
  GET_FORM_TEMPLATE,
  GET_MODULE_SUGGESTIONS,
  GET_OBSERVATION,
  GET_PLAN,
  RESET_ACTIVITIES,
  SET_ACTIVITIES,
  SET_LOADING_ACTIVITIES_FLAG,
  SET_LOADING_FLAG,
  SET_MODULE_SUGGESTIONS,
  SET_PROJECT,
  UPDATE_PROJECT_STANDARD_INFO,
} from './constants';

export function setLoadingFlag(loading) {
  return {
    type: SET_LOADING_FLAG,
    loading,
  };
}

export function setActivitiesLoadingFlag(areActivitiesLoading) {
  return {
    type: SET_LOADING_ACTIVITIES_FLAG,
    areActivitiesLoading,
  };
}

export function fetchProject(callBack403, params) {
  return {
    type: FETCH_PROJECT,
    callBack403,
    params,
  };
}

export function updateProjectStandardInfo(projectInfo, callback) {
  return {
    type: UPDATE_PROJECT_STANDARD_INFO,
    projectInfo,
    callback,
  };
}

export function detachProjectMedia(projectMediaId, callback) {
  return {
    type: DETACH_PROJECT_MEDIA,
    projectMediaId,
    callback,
  };
}

export function attachProjectMedia(projectMedia, callback) {
  return {
    type: ATTACH_PROJECT_MEDIA,
    projectMedia,
    callback,
  };
}

export function setProject(project) {
  return {
    type: SET_PROJECT,
    project,
  };
}

export function setActivities(projectId, activities) {
  return {
    type: SET_ACTIVITIES,
    projectId,
    activities,
  };
}

export function getActivities(idData) {
  return {
    type: GET_ACTIVITIES,
    idData,
  };
}

export function resetActivities() {
  return {
    type: RESET_ACTIVITIES,
  };
}

export function getObservation(id, callback, callbackError, params) {
  return {
    type: GET_OBSERVATION,
    id,
    callback,
    callbackError,
    params,
  };
}

export function getFormTemplate(id, callback, callbackError, params) {
  return {
    type: GET_FORM_TEMPLATE,
    id,
    callback,
    callbackError,
    params,
  };
}

export function getForm(id, callback, callbackError, params) {
  return {
    type: GET_FORM,
    id,
    callback,
    callbackError,
    params,
  };
}

export function getPlan(id, callback, callbackError, params) {
  return {
    type: GET_PLAN,
    id,
    callback,
    callbackError,
    params,
  };
}

export const getModuleSuggestions = (featureFlags, workspaceId) => ({
  type: GET_MODULE_SUGGESTIONS,
  featureFlags,
  workspaceId,
});

export const setModuleSuggestions = modules => ({
  type: SET_MODULE_SUGGESTIONS,
  modules,
});
