import React from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import Member from 'components/Member';
import MemberCellSkeleton from 'components/SkeletonScreen/components/MemberCellSkeleton';
import { getUserData } from 'containers/GlobalWrapper/actions';
import { selectUsers } from 'containers/GlobalWrapper/selectors';

export function MemberCell(props) {
  const { loading, member, isFirstColumnInTable } = props;

  if (loading) {
    return <MemberCellSkeleton />;
  }

  return (
    <Member member={member} displayAvatar={false} isFirstColumnInTable={isFirstColumnInTable} />
  );
}
MemberCell.propTypes = {
  member: PropTypes.object,
  loading: PropTypes.bool,
  isFirstColumnInTable: PropTypes.bool,
};

const mapStateToProps = createStructuredSelector({
  members: selectUsers(),
});

const mapDispatchToProps = {
  dispatchGetUserInfo: getUserData,
};

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default withConnect(MemberCell);
