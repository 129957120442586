import React, { useEffect, useRef, useState } from 'react';

import { Box } from '@mui/material';
import WebViewer from '@pdftron/webviewer';
import * as Sentry from '@sentry/react';
import PropTypes from 'prop-types';
import { renderToStaticMarkup } from 'react-dom/server';
import { injectIntl, intlShape } from 'react-intl';
import { connect, useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import SparkMD5 from 'spark-md5';
import uuid from 'uuid';

import eyeIcon from 'assets/images/eye.svg';
import eyeIconCross from 'assets/images/eyeCross.svg';
import penIcon from 'assets/images/pen.svg';
import penIconCross from 'assets/images/penCross.svg';
import qrcode from 'assets/images/qrcode.svg';
import saveIconDisabled from 'assets/images/saveDisabled.svg';
import saveIconEnabled from 'assets/images/saveEnabled.svg';
import BannerOffer from 'components/Banner/BannerOffer';
import { findFilledIcon } from 'components/icon-picker';
import PlanPinSvg from 'components/PlanViewer/PlanPinSvg';
import config from 'config/config';
import addGlobalReducer from 'containers/GlobalWrapper/reducer';
import { selectLoggedUserData, selectModules } from 'containers/GlobalWrapper/selectors';
import { setGlobalMessage } from 'containers/MessageHandler/actions';
import { updateDocument } from 'containers/OneProjectPage/DocumentsPage/actions';
import oneDocumentsPageReducer from 'containers/OneProjectPage/DocumentsPage/reducer';
import documentsPageSaga from 'containers/OneProjectPage/DocumentsPage/saga';
import { updatePlan } from 'containers/OneProjectPage/PlansPage/actions';
import plansPageReducer from 'containers/OneProjectPage/PlansPage/reducer';
import plansPageSaga from 'containers/OneProjectPage/PlansPage/saga';
import { useFeatureFlags } from 'contexts/FeatureFlagsProvider';
import { Routing } from 'routing/routing';
import globalMessages from 'translations/messages/global-messages';
import Analytics from 'utils/Analytics';
import { generateChunks } from 'utils/chunkUploader';
import { fileChunkSize, MODULE_TYPES } from 'utils/constants';
import { getFileTypeFromBlob } from 'utils/fileType';
import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';
import localStorageUser from 'utils/localStorageUser';
import { generateQRCode } from 'utils/qrcode/qrcode';
import { replaceUrlFallback } from 'utils/url';
import getUserFullName from 'utils/users';

import AddItemButton from './add-items-controls/AddItemButton';
import { displayFirstPageOnly, onViewerScroll } from './utils';

const redColorRGB = {
  r: 233,
  g: 79,
  b: 61,
};

const blueColorRGB = {
  r: 76,
  g: 171,
  b: 242,
};

const greenColorRGB = {
  r: 73,
  g: 203,
  b: 12,
};

const blackColorRGB = {
  r: 0,
  g: 0,
  b: 0,
};

const QR_CODE_SIZE = 800;
const PDFTRON_FCO_TYPE = 'x-fcoType';
const PDFTRON_FCO_TYPE_QRCODE = 'QR_CODE';

const addImageAsAnnotation = (annotationManager, pdfDocument, imageSrc) => {
  const pageInfo = pdfDocument.getPageInfo(1);
  const halfWidth = pageInfo.width / 4;
  const halfHeight = pageInfo.height / 4;

  const qrCodeWidth = QR_CODE_SIZE > halfWidth ? halfWidth : QR_CODE_SIZE;
  const qrCodeHeight = QR_CODE_SIZE > halfHeight ? halfHeight : QR_CODE_SIZE;

  const qrCodeSize = qrCodeWidth > qrCodeHeight ? qrCodeHeight : qrCodeWidth;

  const qrCodeX = pageInfo.width - qrCodeSize - 16;
  const qrCodeY = pageInfo.height - qrCodeSize - 16;

  const stampAnnotation = new Annotations.StampAnnotation();
  stampAnnotation.PageNumber = 1;
  stampAnnotation.X = qrCodeX;
  stampAnnotation.Y = qrCodeY;
  stampAnnotation.Width = qrCodeSize;
  stampAnnotation.Height = qrCodeSize;

  stampAnnotation.ImageData = imageSrc;
  stampAnnotation.Author = annotationManager.getCurrentUser();
  stampAnnotation.setCustomData(PDFTRON_FCO_TYPE, PDFTRON_FCO_TYPE_QRCODE);

  annotationManager.addAnnotation(stampAnnotation);
  annotationManager.redrawAnnotation(stampAnnotation);
};

function moveArrayItem(arr, fromIndex, toIndex) {
  const item = arr.splice(fromIndex, 1)[0];
  arr.splice(toIndex, 0, item);
  return arr;
}

function moveByToolName(arr, toolName, toIndex) {
  const fromIndex = arr.findIndex(item => item.toolName === toolName);
  if (fromIndex > -1) {
    return moveArrayItem(arr, fromIndex, toIndex);
  }
  return arr;
}

function moveByDataElement(arr, dataElement, toIndex) {
  const fromIndex = arr.findIndex(item => item.dataElement === dataElement);
  if (fromIndex > -1) {
    return moveArrayItem(arr, fromIndex, toIndex);
  }
  return arr;
}

function insertSeparator(arr, index) {
  const element = {
    hidden: ['small-mobile'],
    type: 'divider',
  };

  // Insert the element at the index
  arr.splice(index, 0, element);
  return arr;
}

function insertCustomAction({
  elements,
  dataElement,
  index,
  img,
  onClick,
  title,
  isDisabled = false,
}) {
  let element = {
    type: 'actionButton',
    dataElement,
    img,
    onClick,
    title,
  };

  element = isDisabled ? { ...element, className: 'disabled' } : element;
  elements.splice(index, 0, element);
  return elements;
}

function applyToolMeasurementStyle(tool, instance, isDocument = false, color = redColorRGB) {
  const scale = isDocument ? 100 : 1;
  tool.setStyles({
    Scale: [[1, 'm'], [scale, 'm']],
    StrokeColor: new instance.Annotations.Color(color.r, color.g, color.b, 0.8),
    FillColor: new instance.Annotations.Color(color.r, color.g, color.b, 0.3),
    StrokeThickness: 1,
  });
}

function applyToolsStyle(instance, isDocument = false) {
  const { documentViewer } = instance.Core;

  const toolListRed = [
    'AnnotationCreateDistanceMeasurement',
    'AnnotationCreateAreaMeasurement',
    'AnnotationCreateRectangularAreaMeasurement',
    'AnnotationCreateEllipseMeasurement',
  ];

  const toolListBlue = [
    'AnnotationCreateDistanceMeasurement2',
    'AnnotationCreateAreaMeasurement2',
    'AnnotationCreateRectangularAreaMeasurement2',
    'AnnotationCreateEllipseMeasurement2',
  ];

  const toolListBlack = [
    'AnnotationCreateDistanceMeasurement3',
    'AnnotationCreateAreaMeasurement3',
    'AnnotationCreateRectangularAreaMeasurement3',
    'AnnotationCreateEllipseMeasurement3',
  ];

  const toolListGreen = [
    'AnnotationCreateDistanceMeasurement4',
    'AnnotationCreateAreaMeasurement4',
    'AnnotationCreateRectangularAreaMeasurement4',
    'AnnotationCreateEllipseMeasurement4',
  ];

  toolListRed.forEach(toolName => {
    const tool = documentViewer.getTool(toolName);
    applyToolMeasurementStyle(tool, instance, isDocument, redColorRGB);
  });

  toolListBlue.forEach(toolName => {
    const tool = documentViewer.getTool(toolName);
    applyToolMeasurementStyle(tool, instance, isDocument, blueColorRGB);
  });

  toolListBlack.forEach(toolName => {
    const tool = documentViewer.getTool(toolName);
    applyToolMeasurementStyle(tool, instance, isDocument, blackColorRGB);
  });

  toolListGreen.forEach(toolName => {
    const tool = documentViewer.getTool(toolName);
    applyToolMeasurementStyle(tool, instance, isDocument, greenColorRGB);
  });
}

const PdfTronViewerFull = props => {
  const {
    pins,
    docUrl,
    addZoomButton,
    match,
    overflow,
    onPageLoadSuccess,
    changeLoader,
    entityType,
    statuses,
    updatePin,
    moduleId,
    plan,
    seeAnnotations,
    hasOfferLimitation,
    intl,
    history,
    tools,
    pdfDocument,
    isFirstPageOnly,
    userInfo,
  } = props;
  const { params } = match;
  const { organizationId, idData } = params;
  const featureFlags = useFeatureFlags();
  const dispatch = useDispatch();
  const modules = useSelector(selectModules());
  const [tronId] = useState(uuid.v4());
  const [annotationSelected, setAnnotationSelected] = useState();
  const viewer = useRef(null);
  const [isClickToPlacePinMode, setIsClickToPlacePinMode] = useState(false);
  const [triggerClickToPlacePin, setTriggerClickToPlacePin] = useState({ x: 0, y: 0 });
  const shouldAddItems = featureFlags?.plan_add_items;
  const [pdfTronInstance, setPdfTronInstance] = useState();
  const [docViewer, setDocViewer] = useState(null);
  const [isAnnotating, setIsAnnotating] = useState(false);

  const getStatusColor = status_id => statuses.find(status => status.id === status_id)?.color;

  useEffect(
    () => {
      const pdftronCore = pdfTronInstance?.Core;
      const pdftronUI = pdfTronInstance?.UI;
      if (pdftronCore && pdftronUI) {
        if (isClickToPlacePinMode) {
          pdftronCore.annotationManager.enableReadOnlyMode();
        } else {
          const { documentViewer } = pdfTronInstance.Core;
          pdftronCore.annotationManager.disableReadOnlyMode();
          const panTool = documentViewer.getTool(window.Core.Tools.ToolNames.PAN);
          documentViewer.setToolMode(panTool);
        }
      }
    },
    [isClickToPlacePinMode, pdfTronInstance?.UI],
  );

  const createPinIdNode = pin => {
    const textNode = document.createElementNS('http://www.w3.org/2000/svg', 'text');
    textNode.setAttribute('text-anchor', 'middle');
    textNode.setAttribute('font-size', '12');
    textNode.setAttribute('font-weight', 'bold');
    textNode.setAttribute('fill', '#000');
    textNode.setAttribute('transform', 'translate(30 75)');
    textNode.setAttribute(
      'style',
      'text-shadow: 2px 0 0 #fff, -2px 0 0 #fff, 0 2px 0 #fff, 0 -2px 0 #fff, 1px 1px #fff, -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff',
    );
    textNode.innerHTML = `#${pin?.legacy_id}`;
    return textNode;
  };

  const createPinStampImageData = (pin, withCrossHair) => {
    const module = modules.find(m => m.id === pin.module_id);

    const IconComponent = findFilledIcon({ name: module?.icon });
    const parser = new DOMParser();
    const moduleIcon = renderToStaticMarkup(<IconComponent />);

    const pinSvg = renderToStaticMarkup(
      <PlanPinSvg
        color={pin.status_id ? getStatusColor(pin.status_id) : module?.color}
        key={pin.id}
        height={withCrossHair ? 85 : 75}
        showCrossHair={withCrossHair}
      />,
    );

    const pinSvgDOM = parser.parseFromString(pinSvg, 'image/svg+xml');
    const moduleIconSVG = parser.parseFromString(moduleIcon, 'image/svg+xml');

    const { children } = moduleIconSVG.getElementsByTagName('svg')[0];
    for (let i = 0; i < children.length; i++) {
      const newChild = children[i].cloneNode(true);
      newChild.setAttribute('transform', 'translate(18, 18)');
      newChild.setAttribute('fill', '#fff');
      pinSvgDOM.getElementsByTagName('svg')[0].appendChild(newChild.cloneNode(true));
    }
    if (pin.legacy_id) {
      pinSvgDOM.getElementsByTagName('svg')[0].appendChild(createPinIdNode(pin));
    }
    return pinSvgDOM.getElementsByTagName('svg')[0];
  };

  const dispatchUpdatePlan = (blob, spark2, uuidBlobFile) => {
    dispatch(
      updatePlan(
        {
          ...plan,
          resources: {
            ...plan?.media_resource,
            newBlob: blob,
            hash: spark2 && spark2.end(),
            type: blob.type,
            name: plan?.name,
            size: blob.size,
            uuidBlobFile,
          },
          size: blob.size,
        },
        () => {
          Analytics.track('plans_tools_save');
          props.dispatchSetGlobalMessage({
            type: 'success',
            intlMessage: {
              id: 'update_successful',
            },
            verticalPosition: 'bottom',
            horizontalPosition: 'left',
          });
        },
      ),
    );
  };

  const dispatchUpdateDocument = (blob, spark2, uuidBlobFile) => {
    const newDocument = {
      ...pdfDocument,
      media_resource: {
        ...pdfDocument?.media_resource,
        newBlob: blob,
        hash: spark2 && spark2.end(),
        type: blob.type,
        name: pdfDocument?.name,
        size: blob.size,
        uuidBlobFile,
      },
      size: blob.size,
      parent_id: !pdfDocument.parent_id ? 'root' : pdfDocument.parent_id,
    };
    dispatch(updateDocument(newDocument));
  };

  const updateMousePin = () => {
    if (!pdfTronInstance || !docViewer) return;
    const { annotationManager, documentViewer } = pdfTronInstance.Core;
    const pinContainer = documentViewer.getViewerElement().getElementsByClassName('pins-container');
    const allAnnots = annotationManager.getAnnotationsList();

    const pinSvgEncoded = `data:image/svg+xml,${encodeURIComponent(
      createPinStampImageData(
        {
          module_id: moduleId,
        },
        true,
      ).outerHTML,
    )}`;

    if (!isClickToPlacePinMode) {
      documentViewer.getViewerElement().style.cursor = 'default';
    } else {
      documentViewer.getViewerElement().style.cursor = `url("${pinSvgEncoded}") 30.5 72, crosshair`;
    }
    if (pinContainer.length) {
      pinContainer[0].style.display = isClickToPlacePinMode ? 'none' : 'block';
    }

    allAnnots.forEach(annot => {
      annot.Listable = !isClickToPlacePinMode;
    });

    annotationManager.drawAnnotationsFromList(allAnnots);
  };

  // Create all annotations on the plan
  useEffect(
    () => {
      if (!docViewer) return;

      // create a div and append it to the document viewer
      const div = document.createElement('div');
      div.setAttribute('class', 'pins-container');
      div.setAttribute('style', 'position: absolute; top: 0; left: 0; width: 100%; height: 100%');
      // remove div with pins if it already exists
      const pinsDiv = docViewer.getViewerElement().getElementsByClassName('pins-container');
      // remove div with pins if it already exists
      if (pinsDiv.length) {
        pinsDiv[0].remove();
      }
      // add div with pins to the document viewer
      docViewer.getViewerElement().appendChild(div);
      // add custom style to the document viewer and keep actual styles
      const viewerEl = docViewer.getViewerElement();
      const viewerElStyle = viewerEl.getAttribute('style');
      viewerEl.setAttribute('style', `${viewerElStyle} position: relative;`);

      pins.forEach(pin => {
        const itemPin = createPinStampImageData(pin);

        itemPin.setAttribute(
          'style',
          `position: absolute; left: calc(${100 * pin?.position_x}% - ${30.5}px); top: calc(${100 *
            pin?.position_y}% - ${60}px); cursor: pointer;`,
        );

        div.appendChild(itemPin);
        // add click event to the pin
        itemPin.addEventListener('click', () => {
          window.open(
            entityType === MODULE_TYPES.observations
              ? Routing.observations.detail(organizationId, idData, pin.module_id, pin.id)
              : Routing.forms.detail(organizationId, idData, pin.module_id, pin.id),
            '_blank',
          );
        });
      });
      updateMousePin();
    },
    [pins, docViewer],
  );

  useEffect(
    () => {
      updateMousePin();
    },
    [isClickToPlacePinMode],
  );

  // show hide annotations with props seeAnnotations
  useEffect(
    () => {
      if (!pdfTronInstance || !docViewer) return;

      const { annotationManager } = pdfTronInstance.Core;
      const allAnnots = annotationManager.getAnnotationsList();
      if (!seeAnnotations) {
        annotationManager.hideAnnotations(allAnnots);
      } else {
        annotationManager.showAnnotations(allAnnots);
      }
    },
    [seeAnnotations],
  );

  useEffect(() => {
    WebViewer(
      {
        path: '/webviewer',
        l: config.pdfTronLicenseKey,
        forceClientSideInit: true,
        fullAPI: true,
        disabledElements: [
          'toolbarGroup-View',
          'toolbarGroup-Edit',
          'toolbarGroup-Insert',
          'toolbarGroup-FillAndSign',
          'leftPanelButton',
          'toolbarGroup-Forms',
          'menuButton',
          'viewControlsButton',
          'annotationPopup',
          'contextMenuPopup',
          'stickyToolGroupButton',
          ...(!addZoomButton ? ['zoomOverlayButton'] : []),
        ],
      },
      viewer.current,
    ).then(instance => {
      const { annotationManager, documentViewer } = instance.Core;
      const documentViewerUI = instance.UI;
      const viewerEl = documentViewer.getViewerElement();
      const language = localStorageUser.getLanguageUserFromLocalStorage();
      const userStore = localStorageUser.getUserStore();
      const oktaToken = localStorageUser.getOktaTokenStorage();
      const currentUserId = oktaToken?.accessToken?.claims?.fcUserId;
      let isItemVisible = tools.itemsVisibilityOptionEnabled;
      let isAnnotationVisible = tools.annotationAreVisible;
      const currentUser = userStore[currentUserId];
      annotationManager.setCurrentUser(getUserFullName(userInfo || currentUser));

      applyToolsStyle(instance, !!pdfDocument);

      function disableSaveButton() {
        documentViewerUI.updateElement('saveButton', {
          className: 'disabled',
          img: saveIconDisabled,
        });
      }

      function enableSaveButton() {
        documentViewerUI.updateElement('saveButton', { className: '', img: saveIconEnabled });
      }

      const handleSaveDocument = async () => {
        if (hasOfferLimitation) {
          history.push(Routing.organizations.contract(organizationId));
          return;
        }

        documentViewer.setToolMode(documentViewer.getTool(window.Core.Tools.ToolNames.PAN));

        const doc = documentViewer.getDocument();
        const xfdfString = await annotationManager.exportAnnotations({ useDisplayAuthor: true });
        const data = await doc.getFileData({
          // saves the document with annotations in it
          xfdfString,
        });
        const arr = new Uint8Array(data);
        const fileReader = new FileReader();
        const blob = new Blob([arr], { type: 'application/pdf' });

        fileReader.onload = () => {
          const spark2 = new SparkMD5.ArrayBuffer();
          spark2.append(fileReader.result);

          if (blob.size > fileChunkSize) {
            const fileName = plan ? plan?.name : pdfDocument?.name;
            const file = new File([blob], fileName, {
              type: blob.type,
            });

            const genChunksParams = {
              file,
              filesUpload: [],
              singleFileSelection: true,
              filesToUpload: [blob],
              setFiles: files => {
                if (plan) {
                  dispatchUpdatePlan(blob, spark2, files[0].uuidBlobFile);
                  disableSaveButton();
                } else if (pdfDocument) {
                  dispatchUpdateDocument(blob, spark2, files[0].uuidBlobFile);
                  disableSaveButton();
                }
              },
            };

            generateChunks(genChunksParams);
          } else if (plan) {
            dispatchUpdatePlan(blob, spark2);
            disableSaveButton();
          } else if (pdfDocument) {
            dispatchUpdateDocument(blob, spark2);
            disableSaveButton();
          }
        };
        fileReader.readAsArrayBuffer(blob);
      };

      // set language
      instance.UI.setLanguage(language);

      setPdfTronInstance(instance);
      // Disable all features except annotations
      documentViewerUI.disableFeatures(Object.values(documentViewerUI.Feature));
      documentViewerUI.enableFeatures(documentViewerUI.Feature.Annotations);
      documentViewerUI.enableFeatures(documentViewerUI.Feature.Measurement);
      documentViewerUI.setToolbarGroup('toolbarGroup-Annotate');
      documentViewer.setToolMode(documentViewer.getTool(window.Core.Tools.ToolNames.PAN));
      documentViewer.addEventListener('toolModeUpdated', tool => {
        const isCreationTool = tool.name.startsWith('AnnotationCreate');
        setIsAnnotating(isCreationTool);
      });

      documentViewerUI.addEventListener('click', e => {
        const pagePoint = documentViewer.getViewerCoordinatesFromMouseEvent(e);
        const rotation = documentViewer.getCompleteRotation(1);

        if (rotation === 1) {
          setTriggerClickToPlacePin({
            x: 1 - (pagePoint.y < 0 ? 0 : pagePoint.y) / documentViewer.getPageHeight(1),
            y: (pagePoint.x < 0 ? 0 : pagePoint.x) / documentViewer.getPageWidth(1),
          });
        } else if (rotation === 2) {
          setTriggerClickToPlacePin({
            x: 1 - (pagePoint.x < 0 ? 0 : pagePoint.x) / documentViewer.getPageWidth(1),
            y: 1 - (pagePoint.y < 0 ? 0 : pagePoint.y) / documentViewer.getPageHeight(1),
          });
        } else if (rotation === 3) {
          setTriggerClickToPlacePin({
            x: (pagePoint.y < 0 ? 0 : pagePoint.y) / documentViewer.getPageHeight(1),
            y: 1 - (pagePoint.x < 0 ? 0 : pagePoint.x) / documentViewer.getPageWidth(1),
          });
        } else {
          setTriggerClickToPlacePin({
            x: (pagePoint.x < 0 ? 0 : pagePoint.x) / documentViewer.getPageWidth(1),
            y: (pagePoint.y < 0 ? 0 : pagePoint.y) / documentViewer.getPageHeight(1),
          });
        }
      });

      documentViewer.addEventListener('documentLoaded', () => {
        setDocViewer(documentViewer);
        if (isFirstPageOnly) displayFirstPageOnly(documentViewer);
        if (changeLoader) {
          changeLoader(false);
        }
        const rotation = documentViewer.getCompleteRotation(1);
        onPageLoadSuccess(rotation);
        documentViewer.setMargin(0);
        if (!overflow) {
          documentViewer.setFitMode(documentViewer.FitMode.FitWidth);
        }

        annotationManager.addEventListener(
          'annotationChanged',
          (AnnotationChangedInfoObject, action, { imported }) => {
            if (imported) return;
            enableSaveButton();
            setAnnotationSelected(true);
          },
        );

        viewerEl.addEventListener('wheel', e => onViewerScroll(e, documentViewer));
        const sortStrategy = instance.UI.NotesPanelSortStrategy;
        documentViewerUI.setNotesPanelSortStrategy(sortStrategy.CREATED_DATE);
      });

      documentViewerUI.setHeaderItems(header => {
        let items = header.getHeader('toolbarGroup-Annotate').getItems();
        insertSeparator(items, 5);
        items = insertCustomAction({
          elements: items,
          dataElement: 'qrCodeButton',
          img: qrcode,
          index: 6,
          title: intl.formatMessage(globalMessages.qrcode),
          onClick: () => {
            const currentUrl = window.location.href;
            generateQRCode(replaceUrlFallback(currentUrl), 800).then(data => {
              const base64Img = `data:image/png;base64,${data}`;
              addImageAsAnnotation(annotationManager, documentViewer.getDocument(), base64Img);
            });
          },
        });
        header.getHeader('toolbarGroup-Annotate').update(items);
      });

      documentViewerUI.setHeaderItems(header => {
        let elements = header.getItems();
        elements = elements.filter(el => el.type !== 'divider');

        elements = moveByToolName(elements, 'Pan', 0);
        elements = moveByToolName(elements, 'AnnotationEdit', 1);

        if (addZoomButton) {
          elements = insertSeparator(elements, 2);
          elements = moveByDataElement(elements, 'zoomOverlay', 3);
        }

        if (tools.itemsVisibilityOptionEnabled) {
          elements = insertSeparator(elements, 7);
          elements = insertCustomAction({
            elements,
            dataElement: 'itemVisibilityButton',
            img: isItemVisible ? eyeIcon : eyeIconCross,
            index: 8,
            title: isItemVisible
              ? intl.formatMessage(globalMessages.toolitp_hide_items)
              : intl.formatMessage(globalMessages.toolitp_show_items),
            onClick: () => {
              tools.toggleItemsVisibility();
              isItemVisible = !isItemVisible;
              const title = isItemVisible
                ? intl.formatMessage(globalMessages.toolitp_hide_items)
                : intl.formatMessage(globalMessages.toolitp_show_items);
              documentViewerUI.updateElement('itemVisibilityButton', {
                img: isItemVisible ? eyeIcon : eyeIconCross,
                title,
              });
            },
          });
        }

        if (tools.annotationVisibilityOptionEnabled) {
          elements = insertCustomAction({
            elements,
            dataElement: 'annotationsVisibilityButton',
            img: isAnnotationVisible ? penIcon : penIconCross,
            index: 9,
            title: isAnnotationVisible
              ? intl.formatMessage(globalMessages.toolitp_hide_annotaitons)
              : intl.formatMessage(globalMessages.toolitp_show_annotaitons),
            onClick: () => {
              tools.toggleAnnotationVisibility();
              isAnnotationVisible = !isAnnotationVisible;
              const title = isAnnotationVisible
                ? intl.formatMessage(globalMessages.toolitp_hide_annotaitons)
                : intl.formatMessage(globalMessages.toolitp_show_annotaitons);

              documentViewerUI.updateElement('annotationsVisibilityButton', {
                img: isAnnotationVisible ? penIcon : penIconCross,
                title,
              });
            },
          });
        }

        elements = insertCustomAction({
          elements,
          dataElement: 'saveButton',
          img: saveIconDisabled,
          index: elements.length,
          title: intl.formatMessage(globalMessages.save),
          isDisabled: true,
          onClick: () => {
            handleSaveDocument();
            setIsAnnotating(false);
          },
        });

        header.update(elements);
      });
    });

    return () => {
      if (docViewer) {
        docViewer.closeDocument();
      }
    };
  }, []);

  useEffect(
    () => {
      if (!pdfTronInstance || !docUrl) return;
      (async () => {
        try {
          const res = await fetch(docUrl);
          const blob = await res.blob();
          try {
            const detectedFileType = await getFileTypeFromBlob(blob);
            const mimeTypeObj = {
              'image/jpeg': 'jpg',
              'image/png': 'png',
              'application/pdf': 'pdf',
            };
            const mimeType = mimeTypeObj[detectedFileType] || 'pdf';
            pdfTronInstance.Core.documentViewer.loadDocument(blob, {
              l: config.pdfTronLicenseKey,
              extension: mimeType,
            });
          } catch {
            pdfTronInstance.Core.documentViewer.loadDocument(blob, {
              l: config.pdfTronLicenseKey,
            });
          }
        } catch (err) {
          // Log this error to sentry with a code to identify it
          Sentry.captureException(err, {
            tags: {
              area: 'PDFTRON',
              action: docUrl,
              code: 'FAIL_LOAD_PLAN_DOCUMENT',
            },
          });
        }
      })();
    },
    [pdfTronInstance, docUrl],
  );

  return (
    <>
      <div
        ref={viewer}
        key={`scroll-view-${tronId}`}
        id={`scroll-view-${tronId}`}
        style={{ width: '100%', height: '100%' }}
      />
      {hasOfferLimitation &&
        annotationSelected &&
        !isClickToPlacePinMode && (
          <Box sx={{ margin: '8px 16px', position: 'absolute', bottom: '45px' }}>
            <BannerOffer
              title={intl.formatMessage(globalMessages.offers_custom_modules_banner_title)}
              description={intl.formatMessage(
                globalMessages.offers_business_plan_annotation_description,
              )}
            />
          </Box>
        )}
      <div id="clickToPlacePin">
        {Boolean(shouldAddItems) &&
          docViewer && (
            <AddItemButton
              triggerClickToPlacePin={triggerClickToPlacePin}
              entityType={entityType}
              moduleId={moduleId}
              updatePin={() => {
                const { documentViewer } = pdfTronInstance.Core;
                documentViewer.setToolMode(documentViewer.getTool(window.Core.Tools.ToolNames.PAN));
                setIsClickToPlacePinMode(false);
                updatePin();
              }}
              isPinsVisible={placing => setIsClickToPlacePinMode(!placing)}
              showCustomPin={() => setIsClickToPlacePinMode(true)}
              hideCustomPin={() => setIsClickToPlacePinMode(false)}
              isViewerFull
              isAnnotating={isAnnotating}
            />
          )}
      </div>
    </>
  );
};

PdfTronViewerFull.propTypes = {
  pins: PropTypes.arrayOf(PropTypes.object),
  docUrl: PropTypes.string,
  overflow: PropTypes.bool,
  onPageLoadSuccess: PropTypes.func,
  match: PropTypes.object,
  entityType: PropTypes.string,
  addZoomButton: PropTypes.bool,
  changeLoader: PropTypes.func,
  statuses: PropTypes.array,
  updatePin: PropTypes.func,
  moduleId: PropTypes.string,
  plan: PropTypes.object,
  seeAnnotations: PropTypes.bool,
  dispatchSetGlobalMessage: PropTypes.func,
  hasOfferLimitation: PropTypes.bool,
  intl: intlShape.isRequired,
  history: PropTypes.object.isRequired,
  pdfDocument: PropTypes.object,
  tools: PropTypes.shape({
    annotationVisibilityOptionEnabled: PropTypes.bool,
    itemsVisibilityOptionEnabled: PropTypes.bool,
    annotationAreVisible: PropTypes.bool,
    itemsAreVisible: PropTypes.bool,
    toggleAnnotationVisibility: PropTypes.func,
    toggleItemsVisibility: PropTypes.func,
  }),
  isFirstPageOnly: PropTypes.bool,
  userInfo: PropTypes.object,
};

PdfTronViewerFull.defaultProps = {
  addZoomButton: true,
  overflow: true,
  pins: [],
  isFirstPageOnly: true,
};

const mapDispatchToProps = {
  dispatchSetGlobalMessage: setGlobalMessage,
};

const mapStateToProps = createStructuredSelector({
  userInfo: selectLoggedUserData(),
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

const withSaga = injectSaga({ key: 'plansPage', saga: plansPageSaga });
const withReducer = injectReducer({ key: 'plansPage', reducer: plansPageReducer });
const withReducerGlobal = injectReducer({ key: 'global', reducer: addGlobalReducer });

const withSagaDocument = injectSaga({ key: 'documentsPage', saga: documentsPageSaga });
const withReducerDocument = injectReducer({
  key: 'documentsPage',
  reducer: oneDocumentsPageReducer,
});

export default compose(
  withSaga,
  withReducer,
  withSagaDocument,
  withReducerDocument,
  withReducerGlobal,
  withRouter,
  withConnect,
  injectIntl,
)(PdfTronViewerFull);
