import React, { useCallback, useEffect, useRef, useState } from 'react';

import { IconButton, Tooltip, Typography } from '@mui/material';
import { styled as styledMaterial } from '@mui/material/styles';
import { useTheme, withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import { injectIntl, intlShape } from 'react-intl';
import { useDispatch } from 'react-redux';
import { compose } from 'redux';

import Button from 'components/Button';
import CleanableTextField from 'components/CleanableTextField';
import ConfirmDialog from 'components/dialogs/ConfirmDialog';
import Dialog from 'components/dialogs/Dialog';
import Add from 'components/SvgComponents/icons/Add';
import ArrowCorner from 'components/SvgComponents/icons/ArrowCorner';
import Copy from 'components/SvgComponents/icons/Copy';
import Trash from 'components/SvgComponents/icons/Trash';
import { setGlobalMessage } from 'containers/MessageHandler/actions';
import { useFeatureFlags } from 'contexts/FeatureFlagsProvider';
import { GREY_DARK, RED } from 'theme/colors';
import globalMessages from 'translations/messages/global-messages';
import Analytics from 'utils/Analytics';
import { getFormattedDate, getFormattedTime } from 'utils/dateTimeHumanize';
import { cache } from 'utils/hooks';
import injectReducer from 'utils/injectReducer';
import localStorageUser from 'utils/localStorageUser';

import {
  apiKeyGeneratorApi,
  useCreateApiKeyMutation,
  useDeleteApiKeyMutation,
  useFetchApiKeyQuery,
} from './apiKeyGeneratorApi';

const styles = theme => ({
  contentWrap: {
    backgroundColor: theme.palette.background.white,
    overflow: 'auto',
    display: 'flex',
    flex: 'auto',
    flexDirection: 'column',
    padding: theme.spacing(10),
  },
  contentText: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
  textDisplay: {
    marginBottom: theme.spacing(2),
  },
  link: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    textDecoration: 'none',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  tooltip: {
    backgroundColor: theme.palette.background.dark,
    fontSize: 14,
    borderRadius: 8,
  },
  placement: { textAlign: 'center', marginBottom: '1.5rem' },
  error: {
    color: RED,
    fontSize: 12,
  },
  borderRed: {
    border: '1px transparent solid',
    borderColor: RED,
    borderRadius: 8,
  },
});

const IconButton_Action = styledMaterial(IconButton)(() => ({
  marginLeft: 16,
  backgroundColor: 'none',
  width: 16,
  height: 16,
  borderRadius: '100%',
  '&:hover': {
    cursor: 'pointer',
    backgroundColor: 'transparent',
  },
  padding: 2,
}));

function DialogApiKeyGenerator(props) {
  const featureFlags = useFeatureFlags();
  const theme = useTheme();
  const dispatch = useDispatch();
  const { classes, intl, onCloseDialog } = props;
  const apiKeyRef = useRef(null);
  const emailRef = useRef(null);
  const oktaToken = localStorageUser.getOktaTokenStorage();
  const locale = localStorageUser.getLanguageUserFromLocalStorage();
  const userIdentifier = oktaToken?.idToken?.claims?.email || oktaToken?.accessToken?.claims?.sub;
  const userName = oktaToken?.idToken?.claims?.name;
  const userId = oktaToken?.accessToken?.claims?.fcUserId;
  const { data: apiKey, error, refetch } = useFetchApiKeyQuery(userId);
  const [displayDeleteModal, setDisplayDeleteModal] = useState(false);
  const [showErrorEmail, setShowErrorEmail] = useState(false);

  const [createApiKey] = useCreateApiKeyMutation();
  const [deleteApiKey] = useDeleteApiKeyMutation();

  useEffect(
    () => {
      if (error) {
        dispatch(
          setGlobalMessage({
            type: 'error',
            intlMessage: { id: 'error_refresh_contact_support' },
            active: true,
          }),
        );
      }
    },
    [error],
  );

  const handleCreateApiKey = async () => {
    try {
      await createApiKey({ userId, name: userName, email: userIdentifier }).unwrap();
      refetch();
      Analytics.track('user_api_key_generate');
    } catch (err) {
      dispatch(
        setGlobalMessage({
          type: 'error',
          message: err?.message || err?.data?.message,
          active: true,
        }),
      );
    }
  };

  const handleDeleteApiKey = async () => {
    if (emailRef.current.props.input.value !== userIdentifier) {
      setShowErrorEmail(true);
    } else {
      setShowErrorEmail(false);
      try {
        await deleteApiKey({ userId }).unwrap();
        refetch();
        setDisplayDeleteModal(false);
        dispatch(
          setGlobalMessage({
            type: 'success',
            intlMessage: {
              id: 'api_key_revoke_confirmation',
            },
            active: true,
          }),
        );
        Analytics.track('user_api_key_revoke');
      } catch (err) {
        dispatch(
          setGlobalMessage({
            type: 'error',
            message: err?.message || err?.data?.message,
            active: true,
          }),
        );
      }
    }
  };
  const copyToClipboard = () => {
    navigator.clipboard.writeText(apiKey.key);
    dispatch(
      setGlobalMessage({
        type: 'success',
        intlMessage: {
          id: 'copied_to_clipboard',
        },
        active: true,
      }),
    );
    Analytics.track('user_api_key_copy');
  };

  const handleSubmit = event => {
    event.preventDefault();
    handleDeleteApiKey();
  };

  const renderForm = useCallback(
    () => (
      <form onSubmit={handleSubmit}>
        <Typography className={classes.textDisplay} variant="h4" component="p">
          {intl.formatMessage(globalMessages.email_adress)}
        </Typography>
        <Field
          name="email"
          component={CleanableTextField}
          disabled
          placeholder={intl.formatMessage(globalMessages.email_adress)}
          onKeyPress={e => {
            if (e.key === 'Enter') {
              handleSubmit(e);
            }
          }}
        />
        <div className={classes.contentText}>
          <Typography className={classes.textDisplay} variant="h4" component="p">
            {intl.formatMessage(globalMessages.api_key)}
          </Typography>
          {apiKey && !apiKey?.deleted_at ? (
            <>
              <div className={classes.row}>
                <Field
                  ref={apiKeyRef}
                  name="key"
                  component={CleanableTextField}
                  disabled
                  placeholder={intl.formatMessage(globalMessages.api_key)}
                />
                <Tooltip
                  title={intl.formatMessage(globalMessages.copy)}
                  placement="top"
                  leaveDelay={10}
                  key="tooltip-name-copy"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <IconButton_Action onClick={copyToClipboard}>
                    <Copy disableTooltip width={16} height={16} />
                  </IconButton_Action>
                </Tooltip>
                <Tooltip
                  title={intl.formatMessage(globalMessages.api_key_revoke_title)}
                  placement="top"
                  leaveDelay={10}
                  key="tooltip-name-revoke"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <IconButton_Action onClick={() => setDisplayDeleteModal(true)}>
                    <Trash disableTooltip width={16} height={16} />
                  </IconButton_Action>
                </Tooltip>
              </div>
              <Typography color={GREY_DARK} variant="p" component="p">
                {intl.formatMessage(globalMessages.api_generated_on, {
                  date: getFormattedDate(apiKey?.created_at, locale),
                  hour: getFormattedTime(apiKey?.created_at, locale),
                })}
              </Typography>
            </>
          ) : (
            <Button onClick={handleCreateApiKey} startIcon={<Add />}>
              {intl.formatMessage(globalMessages.generate)}
            </Button>
          )}
        </div>
      </form>
    ),
    [apiKey],
  );

  return (
    <>
      <Dialog
        id="apiKeyGenerator"
        open
        fullWidth
        maxWidth="sm"
        dialogActionVariant="navigation"
        onCloseDialog={onCloseDialog}
        dialogTitleNode={intl.formatMessage(globalMessages.api_key)}
        dialogActionNode={[
          <a
            className={classes.link}
            onClick={() => {}}
            href={featureFlags?.api_key_generator_doc_url || ''}
            target="_blank"
          >
            <ArrowCorner
              height={16}
              width={16}
              color={theme.palette.text.primary}
              style={{ marginRight: '8px' }}
            />
            <Typography color={GREY_DARK} variant="p" component="p">
              {intl.formatMessage(globalMessages.open_documentation)}
            </Typography>
          </a>,
          <Button onClick={onCloseDialog} key="save">
            {intl.formatMessage(globalMessages.done)}
          </Button>,
        ]}
      >
        <div className={classes.contentWrap}>
          <Form
            onSubmit={() => {}}
            initialValues={{
              email: userIdentifier,
              key: apiKey ? `****${apiKey?.key?.substring(apiKey?.key.length - 4)}` : '',
            }}
            render={renderForm}
          />
        </div>
      </Dialog>
      <ConfirmDialog
        open={displayDeleteModal}
        onClose={cache([setDisplayDeleteModal, setShowErrorEmail], [false])}
        onSubmit={handleDeleteApiKey}
        title={intl.formatMessage(globalMessages.api_key_revoke_title)}
        areYouSureLabel={intl.formatMessage(globalMessages.are_you_sure)}
        submitLabel={intl.formatMessage(globalMessages.api_key_revoke)}
        message={intl.formatMessage(globalMessages.api_key_revoke_desc)}
        displayCancel
      >
        <Typography className={classes.placement} component="p" variant="bodyLight">
          {userIdentifier}
        </Typography>
        <Form
          onSubmit={() => {}}
          render={() => (
            <form onSubmit={handleSubmit}>
              <Field
                ref={emailRef}
                name="email"
                component={CleanableTextField}
                placeholder={intl.formatMessage(globalMessages.email_adress)}
                className={showErrorEmail && classes.borderRed}
                onKeyPress={e => {
                  if (e.key === 'Enter') {
                    handleSubmit(e);
                  }
                }}
              />
              {showErrorEmail && (
                <Typography className={classes.error} component="p" variant="bodyLight">
                  {intl.formatMessage(globalMessages.api_key_revoke_confirm)}
                </Typography>
              )}
            </form>
          )}
        />
      </ConfirmDialog>
    </>
  );
}
DialogApiKeyGenerator.propTypes = {
  intl: intlShape.isRequired,
  classes: PropTypes.object.isRequired,
  onCloseDialog: PropTypes.func,
};

const withApiReducer = injectReducer({
  key: apiKeyGeneratorApi.reducerPath,
  reducer: apiKeyGeneratorApi.reducer,
});

export default compose(
  injectIntl,
  withApiReducer,
  withStyles(styles),
)(DialogApiKeyGenerator);
