import React from 'react';

import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';

// #region [styled]
const Div_Wrapper = styled('div')(({ theme, color }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  height: 18,
  backgroundColor: theme.palette.secondary.light,
  color: theme.palette.secondary.main,
  borderRadius: 4,
  ...(color === 'info' && {
    backgroundColor: theme.palette.info.light,
    color: theme.palette.info.main,
  }),
  ...(color === 'success' && {
    backgroundColor: theme.palette.success.light,
    color: theme.palette.success.main,
  }),
  ...(color === 'warning' && {
    backgroundColor: theme.palette.warning.light,
    color: theme.palette.warning.main,
  }),
}));

const Span_Label = styled('span')(({ textBold, customStyles }) => ({
  padding: '2px 8px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  fontStyle: 'normal',
  fontWeight: textBold ? 'bold' : 'normal',
  fontSize: '0.75rem',
  display: 'flex',
  alignItems: 'center',
  fontFamily: textBold ? 'Effra Medium, Roboto' : 'inherit',
  ...customStyles,
}));
// #endregion

export function Tile({
  label,
  color = 'secondary',
  textBold = false,
  className,
  customLabelStyles = {},
}) {
  // #region [render]
  if (!label) return null;
  return (
    <Div_Wrapper className={className} color={color}>
      <Span_Label textBold={textBold} customStyles={customLabelStyles}>
        {label}
      </Span_Label>
    </Div_Wrapper>
  );
  // #endregion
}

Tile.propTypes = {
  className: PropTypes.string,
  color: PropTypes.oneOf(['secondary', 'info', 'success', 'warning']),
  label: PropTypes.node,
  textBold: PropTypes.bool,
  customLabelStyles: PropTypes.object,
};

export default Tile;
