import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import config from 'config/config';
import globalMessages from 'translations/messages/global-messages';
import checkOrSetSlash from 'utils/checkOrSetSlash';
import localStorageUser from 'utils/localStorageUser';

const rootProjectPath = `${checkOrSetSlash(config.apiHostGateway, 'apiHostGateway')}api/`;

export const formsTemplatesApi = createApi({
  reducerPath: 'formsTemplatesApi',
  baseQuery: fetchBaseQuery({
    baseUrl: rootProjectPath,
    prepareHeaders: headers => {
      const oktaToken = localStorageUser.getOktaTokenStorage();
      const token = oktaToken?.accessToken?.accessToken;
      headers.set('Cache-Control', 'No-Store');
      headers.set('Allow-Access-Control-Origin', '*');
      if (token) headers.set('Authorization', `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: ['FormsTemplates'],
  endpoints: builder => ({
    getFormsTemplates: builder.query({
      query: ({ baseApiRoute, search_term, limit, offset }) => ({
        url: `${baseApiRoute}/filters?limit=${limit}&offset=${offset}`,
        method: 'POST',
        body: { search_term },
      }),
      providesTags: ['FormsTemplates'],
      keepUnusedDataFor: 0,
    }),
    createFormTemplate: builder.mutation({
      query: ({ intl, baseApiRoute }) => {
        const language = localStorageUser.getLanguageUserFromLocalStorage();
        return {
          url: baseApiRoute,
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            name: intl.formatMessage(globalMessages.form_name),
            form_languages: [
              {
                language: language || 'en',
                is_default: true,
              },
            ],
            form_template_parts: [
              {
                name: intl.formatMessage(globalMessages.part_name),
                order: 0,
                sections: [
                  {
                    name: intl.formatMessage(globalMessages.section_name),
                    order: 0,
                  },
                ],
              },
            ],
          }),
        };
      },
      invalidatesTags: ['FormsTemplates'],
    }),
    importFormTemplate: builder.mutation({
      query: ({ file, baseApiRoute }) => {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('display_name', file.name);
        formData.append('size', file.size);
        formData.append('mime_type', file.type);
        formData.append('file_name', file.name);
        return {
          url: `${baseApiRoute}/import`,
          method: 'POST',
          body: formData,
        };
      },
      invalidatesTags: ['FormsTemplates'],
    }),
    duplicateFormTemplate: builder.mutation({
      query: ({ id, baseApiRoute }) => ({
        url: `${baseApiRoute}/${id}/duplicate`,
        method: 'POST',
      }),
      invalidatesTags: ['FormsTemplates'],
    }),
    exportFormsTemplates: builder.mutation({
      query: ({ ids, baseApiRoute }) => ({
        url: `${baseApiRoute}/export`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(ids),
      }),
    }),
    deleteFormsTemplates: builder.mutation({
      query: ({ ids, baseApiRoute }) => ({
        url: `${baseApiRoute}/bulk-delete`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(ids),
      }),
      invalidatesTags: ['FormsTemplates'],
    }),
  }),
});

export const {
  useGetFormsTemplatesQuery,
  useCreateFormTemplateMutation,
  useImportFormTemplateMutation,
  useDuplicateFormTemplateMutation,
  useExportFormsTemplatesMutation,
  useDeleteFormsTemplatesMutation,
} = formsTemplatesApi;
