import React from 'react';

const ArrowCorner = props => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.8424 1.5C14.2106 1.5 14.5091 1.79848 14.5091 2.16667V8.76633C14.5091 9.13452 14.2106 9.433 13.8424 9.433C13.4742 9.433 13.1758 9.13452 13.1758 8.76633V3.78839L7.71415 9.25001C7.4538 9.51036 7.03169 9.51036 6.77134 9.25001C6.51099 8.98966 6.51099 8.56755 6.77134 8.3072L12.2452 2.83333H7.24277C6.87458 2.83333 6.5761 2.53486 6.5761 2.16667C6.5761 1.79848 6.87458 1.5 7.24277 1.5H13.8424ZM3.45 1.50001C2.93283 1.50001 2.43684 1.70546 2.07114 2.07116C1.70545 2.43685 1.5 2.93284 1.5 3.45001V12.55C1.5 13.0672 1.70545 13.5632 2.07114 13.9289C2.43684 14.2946 2.93283 14.5 3.45 14.5H12.55C13.0672 14.5 13.5632 14.2946 13.9289 13.9289C14.2946 13.5632 14.5 13.0672 14.5 12.55L14.5 11.5C14.5 11.5 14.5 10.7688 13.85 10.7688C13.2 10.7688 13.2 11.5 13.2 11.5V12.55C13.2 12.7224 13.1315 12.8877 13.0096 13.0096C12.8877 13.1315 12.7224 13.2 12.55 13.2H8.65H7.35H3.45C3.27761 13.2 3.11228 13.1315 2.99038 13.0096C2.86848 12.8877 2.8 12.7224 2.8 12.55V3.45001C2.8 3.27762 2.86848 3.11229 2.99038 2.9904C3.11228 2.8685 3.27761 2.80001 3.45 2.80001H4.5C4.5 2.80001 5.23125 2.80001 5.23125 2.15001C5.23125 1.50001 4.5 1.50001 4.5 1.50001H3.45Z"
    />
  </svg>
);

export default ArrowCorner;
