import { createSelector } from 'reselect';

import { selectModules } from './modules/selectors';
import { initialState } from './reducer';

const globalState = state => state.global || initialState;

const selectRouteParams = () => createSelector(globalState, dataState => dataState.get('params'));

const selectLoadingFlag = () => createSelector(globalState, dataState => dataState.get('loading'));

const selectAllOrganizationsLoadingFlag = () =>
  createSelector(globalState, dataState => dataState.get('allOrganizationsLoading'));

const selectSpecialRights = () =>
  createSelector(globalState, dataState => dataState.get('specialRights'));

const selectTimezones = () =>
  createSelector(globalState, dataState => dataState.get('timezones').toJS());

const selectMembers = () =>
  createSelector(globalState, dataState =>
    dataState
      .get('members')
      .filter(member => member.get('enabled'))
      .toJS(),
  );

const selectMembersLoadingFlag = () =>
  createSelector(globalState, dataState => dataState.get('areMembersLoading'));

const selectAllMembers = () =>
  createSelector(globalState, dataState => dataState.get('members').toJS());

const selectMembersMap = () => createSelector(globalState, dataState => dataState.get('members'));

const selectUsers = () => createSelector(globalState, dataState => dataState.get('userStore'));

const selectLoggedUserData = () =>
  createSelector(globalState, dataState => dataState.get('loggedUserData'));

const selectIsPopoupBlockerActive = () =>
  createSelector(globalState, dataState => dataState.get('isPopoupBlockerActive'));

const selectBusinessOrganization = () =>
  createSelector(globalState, dataState => dataState.get('organization').toJS());

const selectSuitableBusinessOrganizationId = () =>
  createSelector(globalState, dataState => dataState.get('suitableBusinessOrganizationId'));

const selectBusinessOrganizationMap = () =>
  createSelector(globalState, dataState => dataState.get('organization'));

const selectGlobalWorkspace = () =>
  createSelector(globalState, dataState => dataState.get('workspace').toJS());

const selectEmailRegistration = () =>
  createSelector(
    globalState,
    dataState =>
      dataState.get('emailRegistration') ? dataState.get('emailRegistration').toJS() : null,
  );

const selectEmailRegistrationMap = () =>
  createSelector(globalState, dataState => dataState.get('emailRegistration'));

const selectAllOrganizations = () =>
  createSelector(globalState, dataState => dataState.get('organizations').toJS());

const selectAllOrganizationsMap = () =>
  createSelector(globalState, dataState => dataState.get('organizations'));

const selectLoadingFlagGlobal = () =>
  createSelector(globalState, dataState => dataState.get('loading'));
const selectProjectDeleted = () =>
  createSelector(globalState, dataState => dataState.get('projectDeleted'));

const selectProject = () => createSelector(globalState, dataState => dataState.get('project'));

const selectDownloadBlob = () =>
  createSelector(globalState, dataState => dataState.get('downloadBlob'));
const selectUserLinkedCompanies = () =>
  createSelector(globalState, dataState => dataState.get('userLinkedCompanies').toJS());

const selectPlan = id => createSelector(globalState, dataState => dataState.get('plans').get(id));

const selectLogos = () => createSelector(globalState, dataState => dataState.get('logos').toJS());

const selectUserAccessInfo = () =>
  createSelector(globalState, dataState => dataState.get('userAccessInfo'));

export {
  selectAllMembers,
  selectAllOrganizations,
  selectAllOrganizationsLoadingFlag,
  selectAllOrganizationsMap,
  selectBusinessOrganization,
  selectBusinessOrganizationMap,
  selectDownloadBlob,
  selectEmailRegistration,
  selectEmailRegistrationMap,
  selectGlobalWorkspace,
  selectIsPopoupBlockerActive,
  selectLoadingFlag,
  selectLoadingFlagGlobal,
  selectLoggedUserData,
  selectLogos,
  selectMembers,
  selectMembersLoadingFlag,
  selectMembersMap,
  selectModules,
  selectPlan,
  selectProject,
  selectProjectDeleted,
  selectRouteParams,
  selectSpecialRights,
  selectSuitableBusinessOrganizationId,
  selectTimezones,
  selectUserAccessInfo,
  selectUserLinkedCompanies,
  selectUsers,
};
