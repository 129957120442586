import React from 'react';

import { styled } from '@mui/material';
import PropTypes from 'prop-types';

import TextSkeleton from 'components/SkeletonScreen/components/TextSkeleton';

// #region [styled]
const Div_TextsWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: '100%',
  justifyContent: 'center',
  gap: theme.spacing(2),
}));

const TextSkeleton_MainText = styled(TextSkeleton)(({ hasDescription }) => ({
  width: hasDescription ? '50%' : '80%',
  height: '8px',
  maxWidth: hasDescription ? '100px' : '200px',
}));

const TextSkeleton_Description = styled(TextSkeleton)(() => ({
  width: '80%',
  height: '6px',
  maxWidth: '200px',
}));
// #endregion

const TextCellSkeleton = ({ hasDescription }) => (
  // #region [render]
  <Div_TextsWrapper>
    <TextSkeleton_MainText hasDescription={hasDescription} />
    {hasDescription && <TextSkeleton_Description />}
  </Div_TextsWrapper>
  // #endregion
);

TextCellSkeleton.propTypes = {
  hasDescription: PropTypes.bool,
};

export default TextCellSkeleton;
