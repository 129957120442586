import React from 'react';

import { Checkbox } from '@mui/material';
import PropTypes from 'prop-types';

import CheckBoxSvg from 'components/SvgComponents/icons/CheckBox';
import CheckUndefined from 'components/SvgComponents/icons/CheckUndefined';
import UnCheckBoxSvg from 'components/SvgComponents/icons/UnCheckBox';

export function CheckBox(props) {
  const { size } = props;
  return (
    <Checkbox
      className={props.className}
      sx={props.sx}
      color={props.color}
      checkedIcon={props.checkedIcon || <CheckBoxSvg width={size} height={size} />}
      icon={props.icon || <UnCheckBoxSvg width={size} height={size} />}
      indeterminateIcon={<CheckUndefined width={size} height={size} />}
      indeterminate={props.indeterminate}
      checked={props.checked}
      onClick={props.onClick}
      disabled={props.disabled}
      onChange={props.onChange}
      disableRipple={props.disableRipple}
      id={props.id}
    />
  );
}

CheckBox.propTypes = {
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  disableRipple: PropTypes.bool,
  icon: PropTypes.node,
  sx: PropTypes.object,
  className: PropTypes.string,
  checkedIcon: PropTypes.node,
  color: PropTypes.oneOf(['primary', 'secondary', 'default']),
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  indeterminate: PropTypes.bool,
  size: PropTypes.number,
  id: PropTypes.string,
};

CheckBox.defaultProps = {
  checked: false,
  disableRipple: false,
  disabled: false,
  onClick: e => e?.stopPropagation(),
  color: 'primary',
  size: 16,
};

export default CheckBox;
