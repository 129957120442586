import { fromJS, Map } from 'immutable';

import { SET_TRADE } from 'containers/CommonObservationsPage/constants';
import localStorageUser from 'utils/localStorageUser';
import sessionStorageUser from 'utils/sessionStorageUser';

import {
  ADD_USER_TO_STORE,
  INITIALIZE_USER_STORE,
  REMOVE_ORGANIZATION,
  RESET_POPUP_BLOCKER_BLOB,
  SET_ALL_ORGANIZATIONS,
  SET_ALL_ORGANIZATIONS_LOADING_FLAG,
  SET_BUSINESS_ORGANIZATION,
  SET_EMAIL_REGISTRATION,
  SET_LOADING_FLAG,
  SET_LOGGED_USER_DATA,
  SET_LOGOS,
  SET_MEMBERS,
  SET_MEMBERS_LOADING_FLAG,
  SET_PLAN,
  SET_PLAN_FOLDER,
  SET_POPUP_BLOCKER_ACTIVE,
  SET_POPUP_BLOCKER_BLOB,
  SET_PROJECT,
  SET_PROJECT_DELETED,
  SET_ROUTE_PARAMS,
  SET_SPECIAL_RIGHTS,
  SET_TIMEZONES,
  SET_USER_ACCESS_INFO,
  SET_USER_LINKED_COMPANIES,
  SET_WORKSPACE,
} from './constants';
import { SET_COMPANY } from './globalStore/company/constants';
import { SET_DOCUMENT } from './globalStore/documents/constants';
import {
  REMOVE_FORM,
  SET_FORM,
  SET_FORM_PHASE,
  SET_FORM_PRIORITY,
  SET_FORM_STATUS,
} from './globalStore/forms/constants';
import {
  REMOVE_OBSERVATION,
  SET_COMMON_OBSERVATION,
  SET_OBSERVATION,
  SET_OBSERVATION_PHASE,
  SET_OBSERVATION_PRIORITY,
  SET_OBSERVATION_STATUS,
} from './globalStore/observations/constants';
import { SET_AUTHOR } from './globalStore/users/constants';
import {
  SET_CURRENT_MODULE,
  SET_MODULES_FORMS,
  SET_MODULES_OBSERVATIONS_AND_MEETING,
  SET_NEW_MODULE,
  SET_UPDATED_MODULE,
} from './modules/constants';

const getEmailFromOkta = () => {
  const oktaToken = localStorageUser.getOktaTokenStorage();
  return {
    email: oktaToken?.idToken?.claims?.email || oktaToken?.accessToken?.claims?.sub,
  };
};
export const initialState = fromJS({
  params: {},
  loading: false,
  allOrganizationsLoading: false,
  isPopoupBlockerActive: false,
  downloadBlob: null,
  timezones: [],
  specialRights: [],
  members: [],
  areMembersLoading: true,
  userStore: null,
  organization: localStorageUser.getBusinessOrganizationLocalStorage(),
  emailRegistration: sessionStorageUser.getFinalcadTempRegistrationInfo(),
  project: null,
  loggedUserData: getEmailFromOkta(),
  organizations: [],
  workspace: {},
  projectDeleted: false,
  userLinkedCompanies: [],
  modules: [],
  currentModule: null,
  plans: new Map(),
  planFolders: new Map(),
  suitableBusinessOrganizationId: null,
  messages: [],
  companies: new Map(),
  commonObservations: new Map(),
  observationPriorities: new Map(),
  formPriorities: new Map(),
  observationPhases: new Map(),
  formPhases: new Map(),
  observationStatuses: new Map(),
  formStatuses: new Map(),
  observations: new Map(),
  forms: new Map(),
  documents: new Map(),
  trades: new Map(),
  previousMessagesLoading: false,
  authors: [],
  logos: [],
  module_forms: [],
  userAccessInfo: {},
});

function addGlobalReducer(state = initialState, action) {
  switch (action.type) {
    case SET_ROUTE_PARAMS:
      return state.set('params', action.params);
    case SET_SPECIAL_RIGHTS:
      return state.set('specialRights', action.specialRights);
    case SET_LOADING_FLAG:
      return state.set('loading', action.loading);
    case SET_ALL_ORGANIZATIONS_LOADING_FLAG:
      return state.set('allOrganizationsLoading', action.loading);
    case SET_TIMEZONES:
      return state.set('timezones', fromJS(action.timezones));
    case SET_MEMBERS:
      return state.set('members', fromJS(action.members));
    case SET_MEMBERS_LOADING_FLAG:
      return state.set('areMembersLoading', action.areMembersLoading);
    case INITIALIZE_USER_STORE:
      return state.set('userStore', action.userStore);
    case ADD_USER_TO_STORE:
      return state.set('userStore', { ...state.get('userStore'), [action.id]: action.user });
    case SET_LOGGED_USER_DATA:
      return state.set('loggedUserData', action.user);
    case SET_EMAIL_REGISTRATION:
      return state.set('emailRegistration', fromJS(action.emailRegistration));
    case SET_ALL_ORGANIZATIONS:
      return state.set('organizations', fromJS(action.organizations));
    case REMOVE_ORGANIZATION:
      return state.set(
        'organizations',
        state.get('organizations').filter(org => org.get('id') !== action.organizationId),
      );
    case SET_BUSINESS_ORGANIZATION:
      return state.set('organization', fromJS(action.organization));
    case SET_PROJECT:
      return state.set('project', action.project);
    case SET_POPUP_BLOCKER_ACTIVE:
      return state.set('isPopoupBlockerActive', action.isPopoupBlockerActive);
    case SET_POPUP_BLOCKER_BLOB:
      return state.set('downloadBlob', action.downloadBlob);
    case RESET_POPUP_BLOCKER_BLOB:
      return state.set('downloadBlob', null);
    case SET_WORKSPACE:
      return state.set('workspace', fromJS(action.workspace));
    case SET_PROJECT_DELETED:
      return state.set('projectDeleted', action.projectDeleted);
    case SET_USER_LINKED_COMPANIES:
      return state.set('userLinkedCompanies', fromJS(action.companies_ids));
    case SET_MODULES_OBSERVATIONS_AND_MEETING:
      return state.set('modules', fromJS(action.modules));
    case SET_MODULES_FORMS: {
      const newState = state.set('module_forms', fromJS(action.modules));

      return action.append
        ? newState.set('modules', state.get('modules').concat(fromJS(action.modules)))
        : newState;
    }
    case SET_CURRENT_MODULE:
      return state.set('current_module', fromJS(action.currentModule));
    case SET_NEW_MODULE:
      return state.set('modules', state.get('modules').push(fromJS(action.newModule)));
    case SET_PLAN:
      return state.set('plans', state.get('plans').set(action.plan.id, action.plan));
    case SET_PLAN_FOLDER:
      return state.set(
        'planFolders',
        state.get('planFolders').set(action.folder.id, action.folder),
      );
    case SET_UPDATED_MODULE: {
      const moduleList = state.get('modules');
      const index = moduleList.findIndex(item => item.get('id') === action.module.id);
      const module = moduleList.find(item => item.get('id') === action.module.id);

      const updatedModule = {
        ...module.toJS(),
        name: action.module.name,
        names: action.module.names,
        icon: action.module.icon,
        color: action.module.color,
        updated_at: action.module.updated_at,
        enabled: action.module.enabled,
      };

      return state.set(
        'modules',
        fromJS([...moduleList.slice(0, index), updatedModule, ...moduleList.slice(index + 1)]),
      );
    }
    case SET_COMPANY:
      return state.set('companies', state.get('companies').set(action.id, action.company));
    case SET_COMMON_OBSERVATION:
      return state.set(
        'commonObservations',
        state.get('commonObservations').set(action.id, action.commonObservation),
      );
    case SET_OBSERVATION_PRIORITY:
      return state.set(
        'observationPriorities',
        state.get('observationPriorities').set(action.id, action.priority),
      );
    case SET_FORM_PRIORITY:
      return state.set(
        'formPriorities',
        state.get('formPriorities').set(action.id, action.priority),
      );
    case SET_OBSERVATION_PHASE:
      return state.set(
        'observationPhases',
        state.get('observationPhases').set(action.id, action.phase),
      );
    case SET_FORM_PHASE:
      return state.set('formPhases', state.get('formPhases').set(action.id, action.phase));
    case SET_OBSERVATION_STATUS:
      return state.set(
        'observationStatuses',
        state.get('observationStatuses').set(action.id, action.status),
      );
    case SET_FORM_STATUS:
      return state.set('formStatuses', state.get('formStatuses').set(action.id, action.status));
    case SET_OBSERVATION:
      return state.set(
        'observations',
        state.get('observations').set(action.id, action.observation),
      );
    case REMOVE_OBSERVATION:
      return state.set('observations', state.get('observations').delete(action.id));
    case SET_FORM:
      return state.set('forms', state.get('forms').set(action.id, action.form));
    case REMOVE_FORM:
      return state.set('forms', state.get('forms').delete(action.id));
    case SET_DOCUMENT:
      return state.set('documents', state.get('documents').set(action.id, action.document));
    case SET_TRADE:
      return state.set('trades', state.get('trades').set(action.id, action.trade));
    case SET_AUTHOR: {
      const index = state.get('authors').findIndex(item => item.user_id === action.author.user_id);

      if (index === -1) {
        return state.set('authors', state.get('authors').push(action.author));
      }
      return state.setIn(['authors', index], action.author);
    }
    case SET_LOGOS:
      return state.set('logos', fromJS(action.logos));
    case SET_USER_ACCESS_INFO:
      return state.set('userAccessInfo', action.payload);
    default:
      return state;
  }
}
export default addGlobalReducer;
