import {
  ADD_TO_PROJECT_BY_INVITATION,
  ADD_USER_TO_STORE,
  ATTACH_LOGOS,
  CHECK_EMAIL_REGISTRATION,
  CLEAR_POPUP_BLOCKER_BLOB,
  CREATE_AND_ATTACH_MEDIA,
  DELETE_PROJECT,
  DETACH_LOGO,
  DOWNLOAD_ITEM,
  FETCH_ALL_ORGANIZATIONS,
  FETCH_LOGOS,
  FETCH_SPECIAL_RIGHTS,
  FETCH_USER_ACCESS_INFO,
  GET_BUSINESS_ORGANIZATION,
  GET_COMPANY_INFO,
  GET_CURRENT_OFFER,
  GET_MEMBERS,
  GET_PLAN,
  GET_PROJECT,
  GET_TIMEZONES,
  GET_USER_DATA,
  GET_USER_LINKED_COMPANIES,
  GET_WORKSPACE,
  GET_WORKSPACE_BY_ID,
  INITIALIZE_USER_STORE,
  LEAVE_PROJECT,
  REMOVE_ORGANIZATION,
  RESET_POPUP_BLOCKER_BLOB,
  SAVE_BUSINESS_ORGANISATION,
  SAVE_WORKSPACE,
  SET_ALL_ORGANIZATIONS,
  SET_ALL_ORGANIZATIONS_LOADING_FLAG,
  SET_BUSINESS_ORGANIZATION,
  SET_EMAIL_REGISTRATION,
  SET_LOADING_FLAG,
  SET_MEMBERS_LOADING_FLAG,
  SET_LOGGED_USER_DATA,
  SET_LOGOS,
  SET_MEMBERS,
  SET_PLAN,
  SET_POPUP_BLOCKER_ACTIVE,
  SET_POPUP_BLOCKER_BLOB,
  SET_PROJECT,
  SET_PROJECT_DELETED,
  SET_ROUTE_PARAMS,
  SET_SPECIAL_RIGHTS,
  SET_TIMEZONES,
  SET_USER_ACCESS_INFO,
  SET_USER_LINKED_COMPANIES,
  SET_WORKSPACE,
  VERIFY_EMAIL,
} from './constants';

export { getModulesForms, getModulesObservationsAndMeeting } from './modules/actions';

export function setRouteParams(params) {
  return {
    type: SET_ROUTE_PARAMS,
    params,
  };
}

export function createAndAttachMedia(resource, user) {
  return {
    type: CREATE_AND_ATTACH_MEDIA,
    resource,
    user,
  };
}

export function fetchSpecialRights() {
  return {
    type: FETCH_SPECIAL_RIGHTS,
  };
}

export function setSpecialRights(specialRights) {
  return {
    type: SET_SPECIAL_RIGHTS,
    specialRights,
  };
}

export function setLoadingFlag(loading) {
  return {
    type: SET_LOADING_FLAG,
    loading,
  };
}

export function setAllOrganizationsLoadingFlag(loading) {
  return {
    type: SET_ALL_ORGANIZATIONS_LOADING_FLAG,
    loading,
  };
}

export function downloadItem(url, fileName, analyticsTrackName) {
  return {
    type: DOWNLOAD_ITEM,
    url,
    fileName,
    analyticsTrackName,
  };
}

export function getCompanyInfo(params, id, callback) {
  return {
    type: GET_COMPANY_INFO,
    id,
    callback,
    params,
  };
}

export function getTimezones() {
  return {
    type: GET_TIMEZONES,
  };
}

export function setTimezones(timezones) {
  return {
    type: SET_TIMEZONES,
    timezones,
  };
}

export function setMembers(members) {
  return {
    type: SET_MEMBERS,
    members,
  };
}

export function getMembers(routeParams, params) {
  return {
    type: GET_MEMBERS,
    params,
    routeParams,
  };
}

export function setMembersLoadingFlag(areMembersLoading) {
  return {
    type: SET_MEMBERS_LOADING_FLAG,
    areMembersLoading,
  };
}

export function getUserData(id, callback, forceUpdate) {
  return {
    type: GET_USER_DATA,
    id,
    callback,
    forceUpdate,
  };
}

export function addUserToStore({ id, user }) {
  return {
    type: ADD_USER_TO_STORE,
    id,
    user,
  };
}

export function initializeUserStore(userStore) {
  return {
    type: INITIALIZE_USER_STORE,
    userStore,
  };
}

export function setLoggedUserData(user) {
  return {
    type: SET_LOGGED_USER_DATA,
    user,
  };
}

export function saveBusinessOrganization(organization, newName, successCallback) {
  return {
    type: SAVE_BUSINESS_ORGANISATION,
    organization,
    newName,
    successCallback,
  };
}

export function getAllOrganizations(callback, offset, search_pattern, limit, init) {
  return {
    type: FETCH_ALL_ORGANIZATIONS,
    callback,
    offset,
    search_pattern,
    limit,
    init,
  };
}
export function getBusinessOrganization(organizationId, callback, errorCallback) {
  return {
    type: GET_BUSINESS_ORGANIZATION,
    organizationId,
    callback,
    errorCallback,
  };
}

export function setBusinessOrganization(organization) {
  return {
    type: SET_BUSINESS_ORGANIZATION,
    organization,
  };
}

export function setAllOrganizations(organizations) {
  return {
    type: SET_ALL_ORGANIZATIONS,
    organizations,
  };
}

export function removeOrganization(organizationId) {
  return {
    type: REMOVE_ORGANIZATION,
    organizationId,
  };
}

export function getProjectInfo(projectId, callback, updateProjectInfo) {
  return {
    type: GET_PROJECT,
    projectId,
    callback,
    updateProjectInfo,
  };
}

export function setProject(project) {
  return {
    type: SET_PROJECT,
    project,
  };
}

export function addToProjectByInvitation(requestURL, callback) {
  return {
    type: ADD_TO_PROJECT_BY_INVITATION,
    requestURL,
    callback,
  };
}

export function checkEmailRegistration(email, callback, organizationId, deepLinkInfoId) {
  return {
    type: CHECK_EMAIL_REGISTRATION,
    email,
    callback,
    organizationId,
    deepLinkInfoId,
  };
}

export function setEmailRegistration(emailRegistration) {
  return {
    type: SET_EMAIL_REGISTRATION,
    emailRegistration,
  };
}

export function getCurrentOffer(businessOrganisationId, callback) {
  return {
    type: GET_CURRENT_OFFER,
    businessOrganisationId,
    callback,
  };
}

export function verifyEmail(email, callback) {
  return {
    type: VERIFY_EMAIL,
    email,
    callback,
  };
}

export function setIsPopoupBlockerActive(isPopoupBlockerActive) {
  return {
    type: SET_POPUP_BLOCKER_ACTIVE,
    isPopoupBlockerActive,
  };
}

export function setPopoupBlockerObject(downloadBlob) {
  return {
    type: SET_POPUP_BLOCKER_BLOB,
    downloadBlob,
  };
}

export function clearPopupBlockObject() {
  return {
    type: CLEAR_POPUP_BLOCKER_BLOB,
  };
}

export function resetPopupBlockObject() {
  return {
    type: RESET_POPUP_BLOCKER_BLOB,
  };
}

export function leaveProject(projectId, callback, callBackError) {
  return {
    type: LEAVE_PROJECT,
    projectId,
    callback,
    callBackError,
  };
}
export function deleteProject(projectId, callback) {
  return {
    type: DELETE_PROJECT,
    projectId,
    callback,
  };
}

export function getWorkspace({ params }, callback) {
  return {
    type: GET_WORKSPACE,
    params,
    callback,
  };
}

export function getWorkspaceById({ id, callback }) {
  return {
    type: GET_WORKSPACE_BY_ID,
    id,
    callback,
  };
}

export function setWorkspace(workspace) {
  return {
    type: SET_WORKSPACE,
    workspace,
  };
}

export function saveWorkspace(workspace) {
  return {
    type: SAVE_WORKSPACE,
    workspace,
  };
}
export function setProjectDeleted(projectDeleted) {
  return {
    type: SET_PROJECT_DELETED,
    projectDeleted,
  };
}
export function getUserLinkedCompanies({ params }) {
  return {
    type: GET_USER_LINKED_COMPANIES,
    params,
  };
}
export function setUserLinkedCompanies(companies_ids) {
  return {
    type: SET_USER_LINKED_COMPANIES,
    companies_ids,
  };
}

export function getPlan(params, id, callback) {
  return {
    type: GET_PLAN,
    params,
    id,
    callback,
  };
}

export function setPlan(plan) {
  return {
    type: SET_PLAN,
    plan,
  };
}

export const fetchLogos = () => ({
  type: FETCH_LOGOS,
});

export const attachLogos = (medias, callback) => ({
  type: ATTACH_LOGOS,
  medias,
  callback,
});

export const detachLogo = (mediaId, callback) => ({
  type: DETACH_LOGO,
  mediaId,
  callback,
});

export const setLogos = logos => ({
  type: SET_LOGOS,
  logos,
});

export const fetchUserAccessInfo = () => ({
  type: FETCH_USER_ACCESS_INFO,
});

export const setUserAccessInfo = payloadaccessInfo => ({
  type: SET_USER_ACCESS_INFO,
  payload: payloadaccessInfo,
});
