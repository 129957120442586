import React from 'react';

import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';

import TextCell from 'components/FCTable/components/CoreCells/TextCell';
import Calendar from 'components/SvgComponents/icons/Calendar';
import { RED } from 'theme/colors';

const DateCell = ({
  intl,
  date,
  mainTextVariant,
  loading,
  hasIcon,
  hasHour,
  isHourInline,
  isUrgent,
}) => {
  // #region [render]
  let mainText;
  let description;
  if (date) {
    mainText = intl.formatDate(date, {
      year: 'numeric',
      month: 'short',
      day: '2-digit',
    });
    if (hasHour) {
      description = intl.formatDate(date, {
        hour: 'numeric',
        minute: 'numeric',
      });
      if (isHourInline) {
        mainText += ` ${description}`;
        description = null;
      }
    }
  }
  return (
    <TextCell
      mainText={mainText}
      mainTextVariant={mainTextVariant}
      description={description}
      icon={hasIcon ? <Calendar color={isUrgent ? RED : null} height={14} width={14} /> : null}
      loading={loading}
    />
  );
  // #endregion
};

DateCell.propTypes = {
  intl: intlShape.isRequired,
  date: PropTypes.string,
  mainTextVariant: PropTypes.oneOf(['subtitle', 'subtitleLight', 'subtitleBold']),
  loading: PropTypes.bool,
  hasIcon: PropTypes.bool,
  hasHour: PropTypes.bool,
  isHourInline: PropTypes.bool,
  isUrgent: PropTypes.bool,
};

export default injectIntl(DateCell);
